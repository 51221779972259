<template>
    <div class="aboutMe d_flex center" id="about">
        <div class="inner_content d_flex align_center space_around">
            <div class="text_content">
                <h2 class="section_title" v-scroll-reveal.reset>W.H.O.A.M.I</h2>
                <p class="section_content">
                    Senior Software Engineer and DevOps Engineer with over 5 years of experience and a track
                    record in cybersecurity. Currently working as a DevOps engineer at Patricia Technologies.
                    <br />
                    <span class="key">Tech Stacks:</span> PHP/Laravel, Python, Java, Javascript/Vue & React, C# & Go.
                    <br />
                    <span class="key">Databases:</span> MySQL, PostgreSQL, MongoDB, Firebase, NoSQL, Microsoft Sql Server.
                    <br />
                    <span class="key">Backend Specs:</span> REST, GraphQL & ISO 8583.
                    <br />
                    <span class="key">Background Processing & Message Queueing:</span> Redis
                    <br />
                    <span class="key">Infrastructure:</span>
                    AWS, GCP, Azure, Heroku, Digital Ocean.
                    <br />
                    <span class="key">Continuous Integration:</span> Jenkins, CircleCi, Travis CI, Azure DevOps, Gitlab.
                    <br />
                    <span class="key">Languages:</span> English
                </p>
                <div class="linkButton">
                    <LinkButton :to="'#works'" :text="'View Works'"></LinkButton>
                </div>
            </div>

            <div class="img_section" v-scroll-reveal.reset="{ origin: 'top', delay: 500, distance: '50px' }">
                <img src="@/assets/svg/aboutme.svg" alt />
            </div>
        </div>
    </div>
</template>

<script>
import { Intersection_observer } from '@/utils/observer'
const LinkButton = () => import('@/components/LinkButton')
export default {
    components: {
        LinkButton
    },
    mounted() {
        Intersection_observer( this.handleInteract, this.$el)
    },
    methods: {
        async handleInteract(entries, observer) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.$store.dispatch('SET_CURRENT_SECTION', 'About')
                }
            })
        }
    }
}
</script>

<style lang="scss">
.aboutMe {
    min-height: 100vh;
    background-color: #e0f0ed;

    .inner_content {
        width: 80%;
        margin: auto;
        .text_content {
            width: 50%;
            padding: 10px 20px;
            .section_title {
                color: $secondary;
                font-size: 50px;
                margin: 0;
            }

            .section_content {
                color: $primary;
                font-size: 16px;
                width: 80%;
                line-height: 2.2em;

                .key {
                    color: $secondary;
                    font-weight: 900;
                }
            }
            .linkButton {
                width: 200px;
            }
        }

        .img_section {
            width: 50%;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .aboutMe {
        padding: 10px !important;
        .inner_content {
            flex-wrap: wrap-reverse;
            .text_content {
                width: 100% !important;
                .section_title {
                    font-size: 38px;
                }
                .section_content {
                    width: 100%;
                }
            }

            .img_section {
                width: 100% !important;
                display: flex;
                justify-content: center;
                img {
                    width: 90% !important;
                    height: 90% !important;
                    margin: auto;
                }
            }
        }
    }
}
</style>
